import React from "react"
import { graphql } from 'gatsby'

//components
import Layout from "/src/components/Layout"
import Hero from "/src/components/SellBuyPage/Hero"
import CardsSection from "/src/components/SellBuyPage/CardsSection"
import GoldBorder from "/src/components/GoldBorder"
import MultiLayer from "/src/components/SellBuyPage/MultiLayer"
import WhyCBS from "/src/components/SellBuyPage/WhyCBS"
import Services from "/src/components/SellBuyPage/Services"
import QuickLiquidation from "/src/components/SellBuyPage/QuickLiquidation"
import QuickAdministration from "/src/components/SellBuyPage/QuickAdministration"
import MoreInformation from "/src/components/SellBuyPage/MoreInformation"
import ReadyToLiquidate from "/src/components/SellBuyPage/ReadyToLiquidate"
import SEO from "/src/components/seo"

// import * as styles from './kopa-lagerbolag.module.scss';

export const pageQuery = graphql`query BuyQuery($id: String!) {
  markdownRemark(id: {eq: $id}) {
    id
    html
    frontmatter {
      heroSlogan
      heroSloganMobile
      heroTitle
      heroSubtitle
      heroParagraph
      heroGreenSubtitle
      heroSecondParagraph
      heroMainButtonText
      heroMainButtonUrl
      heroButtonRightTextMobile
      heroImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      heroCursiveTextImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      heroTabletLogoLeft {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      heroTabletLogoRight {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      cardsTitle
      cardsCheckboxItems {
        checkboxItem
      }
      cardsParagraph
      cardsSmallText
      cardsImageLeft {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      cardsImageRight {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      cardsOrangeCardTitle
      cardsOrangeCardListItems {
        cardsOrangeCardListItem
      }
      cardsButtonText
      cardsButtonUrl
      cardsTextNextToButton
            testimonialsQuote
      testimonialsImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      testimonialsLogo {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      testimonialsLogoUrl
      testimonialsList {
        text
        author
      }
      whyCbsTitle
      whyCbsSubtitle
      whyCbsCardsContentList {
        title
        content
        icon {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      servicesTitle
      servicesVideoThumbnail {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      servicesBottomText
      servicesServicesList {
        content
      }
      servicesButtonText
      servicesButtonUrl
      servicesImageNextToButton {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      servicesTextNextToButton
      servicesVideoId
      quickLiquidationImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      quickLiquidationTitle
      quickLiquidationFirstParagraph
      quickLiquidationGreenText
      quickLiquidationOrangeText
      quickLiquidationGuarantees {
        guarantee
      }
      quickLiquidationQuote
      quickAdministrationTitle
      quickAdministrationSubtitle
      quickAdministrationFirstParagraph
      quickAdministrationLinkText
      quickAdministrationLinkUrl
      quickAdministrationSecondParagraph
      quickAdministrationButtonText
      quickAdministrationButtonUrl
      quickAdministrationImageRightOfButton {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      quickAdministrationBelowButton
      quickAdministrationImageRight {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      moreInfoTitle
      moreInfoSubtitle
      moreInfoInfos {
        info
        link
      }
      readyToLiquidateTitle
      readyToLiquidateSubtitle
      readyToLiquidateImages {
        employee {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      readyToLiquidateBottomTitle
      readyToLiquidateBottomSubtitle
      readyToLiquidateButtonText
      readyToLiquidateButtonUrl
      readyToLiquidateTextBelowButton
    }
  }
}
`

const BuyPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark

  return (
    <Layout slogan={false}>
      <SEO
        title="Lagerbolag för omgående leverans från CBS"
        description="Starta aktiebolag snabbt och lätt med CBS; köp lagerbolag av oss och få tillgång till ert nya org.nummer inom 2 timmar!"
      />

      <Hero
        isBuyPage={true}
        sloganMobile={frontmatter.heroSloganMobile}
        slogan={frontmatter.heroSlogan}
        title={frontmatter.heroTitle}
        subtitle={frontmatter.heroSubtitle}
        paragraph={frontmatter.heroParagraph}
        greenSubtitle={frontmatter.heroGreenSubtitle}
        second_paragraph={frontmatter.heroSecondParagraph}
        heroMainButtonText={frontmatter.heroMainButtonText}
        heroMainButtonUrl={frontmatter.heroMainButtonUrl}
        heroButtonRightTextMobile={frontmatter.heroButtonRightTextMobile}
        img={frontmatter.heroImage.childImageSharp.fluid.src}
        heroCursiveTextImage={frontmatter.heroCursiveTextImage.childImageSharp.fluid.src}
        heroTabletLogoLeft={frontmatter.heroTabletLogoLeft.childImageSharp.fluid.src}
        heroTabletLogoRight={frontmatter.heroTabletLogoRight.childImageSharp.fluid.src}
      />

      <GoldBorder />

      <CardsSection
        isBuyPage={true}
        title={frontmatter.cardsTitle}
        cardsCheckboxItems={frontmatter.cardsCheckboxItems}
        paragraph={frontmatter.cardsParagraph}
        cardsSmallText={frontmatter.cardsSmallText}
        imageLeft={frontmatter.cardsImageLeft.childImageSharp.fluid.src}
        imageRight={frontmatter.cardsImageRight.childImageSharp.fluid.src}
        cardsOrangeCardTitle={frontmatter.cardsOrangeCardTitle}
        cardsOrangeCardListItems={frontmatter.cardsOrangeCardListItems}
        cardsButtonText={frontmatter.cardsButtonText}
        cardsButtonUrl={frontmatter.cardsButtonUrl}
        cardsTextNextToButton={frontmatter.cardsTextNextToButton}
      />

      <MultiLayer
        isBuyPage={true}
        testimonialsQuote={frontmatter.testimonialsQuote}
        testimonialsImage={frontmatter.testimonialsImage.childImageSharp.fluid.src}
        testimonialsLogo={frontmatter.testimonialsLogo.childImageSharp.fluid.src}
        testimonialsLogoUrl={frontmatter.testimonialsLogoUrl}
        testimonialsList={frontmatter.testimonialsList}
      />

      <GoldBorder />

      <WhyCBS
        isBuyPage
        whyCbsTitle={frontmatter.whyCbsTitle}
        whyCbsSubtitle={frontmatter.whyCbsSubtitle}
        whyCbsCardsContentList={frontmatter.whyCbsCardsContentList}
      />

      <Services
        servicesTitle={frontmatter.servicesTitle}
        servicesVideoThumbnail={frontmatter.servicesVideoThumbnail.childImageSharp.fluid.src}
        servicesBottomText={frontmatter.servicesBottomText}
        servicesServicesList={frontmatter.servicesServicesList}
        servicesButtonText={frontmatter.servicesButtonText}
        servicesButtonUrl={frontmatter.servicesButtonUrl}
        servicesImageNextToButton={frontmatter.servicesImageNextToButton.childImageSharp.fluid.src}
        servicesTextNextToButton={frontmatter.servicesTextNextToButton}
        videoId={frontmatter.servicesVideoId}
      />

      <QuickLiquidation
        quickLiquidationImage={frontmatter.quickLiquidationImage.childImageSharp.fluid.src}
        quickLiquidationTitle={frontmatter.quickLiquidationTitle}
        quickLiquidationFirstParagraph={frontmatter.quickLiquidationFirstParagraph}
        quickLiquidationGreenText={frontmatter.quickLiquidationGreenText}
        quickLiquidationOrangeText={frontmatter.quickLiquidationOrangeText}
        quickLiquidationQuote={frontmatter.quickLiquidationQuote}
        quickLiquidationGuarantees={frontmatter.quickLiquidationGuarantees}
      />

      <QuickAdministration
        isBuyPage={true}
        quickAdministrationTitle={frontmatter.quickAdministrationTitle}
        quickAdministrationSubtitle={frontmatter.quickAdministrationSubtitle}
        quickAdministrationFirstParagraph={frontmatter.quickAdministrationFirstParagraph}
        quickAdministrationSecondParagraph={frontmatter.quickAdministrationSecondParagraph}
        quickAdministrationButtonText={frontmatter.quickAdministrationButtonText}
        quickAdministrationButtonUrl={frontmatter.quickAdministrationButtonUrl}
        quickAdministrationBelowButton={frontmatter.quickAdministrationBelowButton}
        quickAdministrationImageRightOfButton={frontmatter.quickAdministrationImageRightOfButton.childImageSharp.fluid.src}
        quickAdministrationImageRight={frontmatter.quickAdministrationImageRight.childImageSharp.fluid.src}
      />

      <MoreInformation
        moreInfoTitle={frontmatter.moreInfoTitle}
        moreInfoSubtitle={frontmatter.moreInfoSubtitle}
        moreInfoInfos={frontmatter.moreInfoInfos}
      />

      <ReadyToLiquidate
        isBuyPage
        readyToLiquidateTitle={frontmatter.readyToLiquidateTitle}
        readyToLiquidateSubtitle={frontmatter.readyToLiquidateSubtitle}
        readyToLiquidateImages={frontmatter.readyToLiquidateImages}
        readyToLiquidateBottomTitle={frontmatter.readyToLiquidateBottomTitle}
        readyToLiquidateBottomSubtitle={frontmatter.readyToLiquidateBottomSubtitle}
        readyToLiquidateButtonText={frontmatter.readyToLiquidateButtonText}
        readyToLiquidateButtonUrl={frontmatter.readyToLiquidateButtonUrl}
        readyToLiquidateTextBelowButton={frontmatter.readyToLiquidateTextBelowButton}
      />
    </Layout>
  )
}

export default BuyPage
